<template>
  <div class="home">
    <div class="main-content Theme_colors">
      <div class="left-content" v-show="!isMobile">
        <div class="profile">
          <div class="profile-info">
            <div class="avatar-wrapper">
              <img src="https://i.pinimg.com/564x/84/7e/45/847e45870107a9071775ee9e794f1a9f.jpg" alt="Profile Image" class="profile-image">
              <img src="@/assets/srking_crownborder.png" alt="Crown Border" class="crown-border">
            </div>
            <p class="profile-name">Sr. King</p>
          </div>
          <div class="social-icons-box">
            <div class="social-icons Frosted_glass">
              <a class="social-icon" href="#" target="_blank"><i class="fas fa-user"></i></a>
              <a class="social-icon" href="https://shop.king.sv" target="_blank"><i class="fas fa-cart-shopping"></i></a>
              <a class="social-icon" href="https://discord.gg/kingsz" target="_blank"><i class="fab fa-discord"></i></a>
              <a class="social-icon" href="https://t.me/fodebi" target="_blank"><i class="fab fa-telegram"></i></a>
            </div>
          </div>
        </div>
      </div>

      <button class="nav-button" @click="toggleNav">
        <i :class="['fas', navOpen ? 'fa-times' : 'fa-bars']"></i>
      </button>

      <div v-show="!isMobile" style="width: 35rem"></div>

      <div v-show="isMobile" style="width: 100%"></div>

      <div v-show="isMobile" class="profile">
        <div class="profile-info">
          <div class="avatar-wrapper">
            <img src="https://i.pinimg.com/564x/84/7e/45/847e45870107a9071775ee9e794f1a9f.jpg" alt="Profile Image" class="profile-image">
            <img src="@/assets/srking_crownborder.png" alt="Crown Border" class="crown-border">
          </div>
          <p class="profile-name">Sr. King</p>
        </div>
        <div class="social-icons-box">
            <div class="social-icons Frosted_glass">
              <a class="social-icon" href="#" target="_blank"><i class="fas fa-user"></i></a>
              <a class="social-icon" href="https://shop.king.sv" target="_blank"><i class="fas fa-cart-shopping"></i></a>
              <a class="social-icon" href="https://discord.gg/kingsz" target="_blank"><i class="fab fa-discord"></i></a>
              <a class="social-icon" href="https://t.me/fodebi" target="_blank"><i class="fab fa-telegram"></i></a>
            </div>
          </div>
      </div>

      <div class="right-content">
        <div class="box-container">
          <div class="box Frosted_glass blur-info-box">
            <div class="info-box">
              <div class="quote-icon top-left">
                <i class="fas fa-quote-left"></i>
              </div>
              <p class="info-text">{{ dailyPhrase }}</p>
              <div class="quote-icon bottom-right">
                <i class="fas fa-quote-right"></i>
              </div>
            </div>
          </div>
          <div class="box Frosted_glass blur-info-box">
            <div class="info-box">
              <p class="info-text"><span class="digital-clock">{{ currentTime }}</span><br>Time (GMT+1)</p>
            </div>
          </div>
        </div>
        <div class="link-section" v-show="!isMobile">
          <p class="link-header"><i class="fas fa-link"></i> List of my websites</p>
          <div class="website-links">
            <a href="https://shop.king.sv/" class="website-link-text">
              <div class="website-link Frosted_glass">
                <i class="fas fa-cart-shopping"></i><span>Shop</span>
              </div>
            </a>
            <a href="#" class="website-link-text">
              <div class="website-link Frosted_glass">
                <i class="fas fa-gem"></i><span>Ruby</span>
              </div>
            </a>
            <a href="#" class="website-link-text">
              <div class="website-link Frosted_glass">
                <i class="fas fa-globe"></i><span>Projects</span>
              </div>
            </a>
            <a href="#" class="website-link-text">
              <div class="website-link Frosted_glass">
                <i class="fas fa-star"></i><span>Vouches</span>
              </div>
            </a>
            <a href="#" class="website-link-text">
              <div class="website-link Frosted_glass">
                <i class="fas fa-image"></i><span>Gallery</span>
              </div>
            </a>
            <a href="#" class="website-link-text">
              <div class="website-link Frosted_glass">
                <i class="fas fa-user-group"></i><span>Contact</span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div v-show="navOpen" class="mobile-nav">
      <p class="mobile-nav-title"><i class="fas fa-link"></i> List of my websites</p>
      <div class="mobile-nav-links">
        <a href="https://shop.king.sv/" class="website-link">
          <i class="fas fa-cart-shopping"></i><span>Shop</span>
        </a>
        <a href="#" class="website-link">
          <i class="fas fa-globe"></i><span>Soon</span>
        </a>
        <a href="#" class="website-link">
          <i class="fas fa-globe"></i><span>Soon</span>
        </a>
        <a href="#" class="website-link">
          <i class="fas fa-globe"></i><span>Soon</span>
        </a>
        <a href="#" class="website-link">
          <i class="fas fa-globe"></i><span>Soon</span>
        </a>
        <a href="#" class="website-link">
          <i class="fas fa-user-group"></i><span>Contact</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data() {
    return {
      currentTime: '',
      timer: null,
      dailyPhrases: [
        "Living with the destiny to win.",
        "My mind is both my greatest asset and my biggest curse.",
        "A true master is an eternal student.",
        "Do not let your pride blind you.",
        "Haunted by a past I cannot change.",
        "No sleep, no rest... there is no time.",
        "Fear the power you do not see.",
        "The more they try to bring me down, the more they reveal I am on the right path.",
        "The old wounds ache. They are reminders.",
        "I hear you're trash. Bow before your king!",
        "Don't be sad because it's over, be happy because it happened.",
        "I'm doing things that even God would doubt.",
        "They know my name but not what I've been through.",
        "Those I loved won't see tomorrow...",
        "I lost my faith in humanity the moment those I love died.",
        "I've always been a genius, even from a young age.",
        "I was born with talent, it runs in my lineage.",
      ],
      dailyPhrase: '',
      isMobile: false,
      navOpen: false,
    };
  },
  mounted() {
    this.updateCurrentTime();
    this.timer = setInterval(this.updateCurrentTime, 1000);
    this.setDailyPhrase();
    this.checkIfMobile();
    window.addEventListener('resize', this.checkIfMobile);
  },
  methods: {
    updateCurrentTime() {
      const time = new Date();
      const options = {
        timeZone: 'Europe/Lisbon',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
      };
      this.currentTime = new Intl.DateTimeFormat('en-US', options).format(time);
    },
    setDailyPhrase() {
      const randomIndex = Math.floor(Math.random() * this.dailyPhrases.length);
      this.dailyPhrase = this.dailyPhrases[randomIndex];
    },
    checkIfMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
    toggleNav() {
      this.navOpen = !this.navOpen;
    },
  },
  beforeUnmount() {
    clearInterval(this.timer);
    window.removeEventListener('resize', this.checkIfMobile);
  },
};
</script>

<style scoped>
:root {
  font-size: 16px;
}

.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
}

.main-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 2.5rem;
  border-radius: 1.875rem;
  width: 80%;
}

.info-text {
  line-height: 1;
}

.right-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.left-content, .right-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.left-content {
  margin-top: 6%;
}

.box-container {
  display: flex;
  gap: 1.25rem;
  flex-wrap: nowrap;
  justify-content: center;
}

.box {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.9375rem;
  width: 100%;
  max-width: 20.5rem;
}

.info-box {
  position: relative;
  width: 21rem;
  height: 7rem;
  border-radius: 0.9375rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.8rem;
  padding: 1rem;
}

.info-text {
  width: 100%;
  word-wrap: break-word;
}

.info-box:first-child .quote-icon.top-left {
  position: absolute;
  top: 0rem;
  left: 0.5rem;
  font-size: 1.5rem;
}

.info-box:first-child .quote-icon.bottom-right {
  position: absolute;
  bottom: 0rem;
  right: 0.5rem;
  font-size: 1.5rem;
}

.blur-info-box {
  transition: transform 0.3s ease, filter 0.3s ease;
}

.blur-info-box:hover {
  transform: scale(1.1);
}

.link-section {
  width: 100%;
}

.link-header {
  margin-bottom: 1.25rem;
  font-size: 1.5rem;
}

.website-links {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.625rem;
}

.website-link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.9375rem;
  border-radius: 0.6375rem;
  font-size: 1.25rem;
  text-align: center;
  transition: transform 0.3s ease, filter 0.3s ease;
  color: white !important;
  text-decoration: none !important;
}

.website-link i {
  margin-right: 0.5rem;
}

.website-link:hover {
  transform: scale(1.1);
  backdrop-filter: brightness(0.5);
  cursor: none !important;
}

.website-link-text {
  color: white !important;
  text-decoration: none !important;
}

.website-link-text:hover {
  text-decoration: none !important;
  cursor: none !important;
}

.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}

.avatar-wrapper {
  position: relative;
  width: 9.375rem;
  height: 9.375rem;
}

.profile-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.crown-border {
  position: absolute;
  top: -35%;
  left: -9.1%;
  width: 117.5%;
  height: 143.4%;
  pointer-events: none;
}

.profile-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-name {
  font-size: 1.875rem;
  font-weight: bold;
}

.social-icons-box {
  padding: 0.85rem;
  border-radius: 1.875rem;
  backdrop-filter: blur(0.625rem) brightness(0.8);
  transition: transform 0.3s ease, filter 0.3s ease;
  margin-top: -1rem;
}

.social-icons {
  cursor: none;
  display: flex;
  justify-content: center;
}

.social-icons a {
  cursor: none !important;
  color: white;
  font-size: 1.5rem;
  margin: 0 0.625rem;
}

.social-icon {
  transition: transform 0.3s ease, filter 0.3s ease;
}

.social-icon:hover {
  transform: scale(1.1);
}

.social-icons-box:hover {
  transform: scale(1.01);
  backdrop-filter: blur(0.625rem) brightness(0.6);
}

.Frosted_glass {
  backdrop-filter: blur(0.625rem) brightness(0.7);
}

.nav-button {
  display: none;
  position: fixed;
  top: 10px;
  right: 10px;
  font-size: 2rem;
  background: none;
  border: none;
  color: white;
  z-index: 1001;
}

.mobile-nav {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 90%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  padding: 20px;
  animation: fadeIn 0.5s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mobile-nav-title {
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 3rem;
}

.mobile-nav-links {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  width: 100%;
}

.mobile-nav-links .website-link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  border-radius: 0.6375rem;
  font-size: 1.25rem;
  text-align: center;
  color: white !important;
  text-decoration: none !important;
  transition: transform 0.3s ease, filter 0.3s ease;
}

.mobile-nav-links .website-link i {
  margin-right: 0.5rem;
}

.mobile-nav-links .website-link:hover {
  transform: scale(1.1);
  backdrop-filter: brightness(0.5);
  cursor: pointer;
}

@media (max-width: 768px) {
  .main-content {
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 1rem;
  }

  .box-container {
    flex-direction: column;
    align-items: center;
  }

  .nav-button {
    display: block;
  }

  .mobile-nav {
    display: flex;
  }

  .profile {
    margin-bottom: 5rem;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@font-face {
  font-family: 'DigitalAlarmClock';
  src: url("../assets/digital-7.ttf") format('truetype');
}

.digital-clock {
  font-family: 'DigitalAlarmClock', Arial, sans-serif;
  font-size: 3rem;
}
</style>