<template>
    <footer class="footer Theme_colors Frosted_glass">
      <p class="copyright">Copyright © 2023 - 2024 | Made by SrKing & DoodyTco</p>
    </footer>
</template>

<script>
    export default {
    name: 'PageFooter'
    }
</script>

<style scoped>
    .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding: 1px 0;
    background-color: rgba(255, 255, 255, 0.306);
    box-shadow: 0px 0px 10px 5px rgba(37, 37, 37, 0.591);
    }
    .Theme_colors {
    background-color: rgba(255, 255, 255, 0);
    }
    .Frosted_glass {
    backdrop-filter: blur(10px);
    }
    .copyright {
    color: white;
    font-size: 15px;
    letter-spacing: 2px;
    font-weight: 500;
    }
</style>  