<template>
  <div id="app">
    <div class="main">
      <div class="container Theme_colors Frosted_glass">
        <Home />
      </div>
    </div>
    <PageFooter />
  </div>
</template>

<script>
import { inject } from "@vercel/analytics"
import Home from './components/Home.vue';
import PageFooter from './components/Footer.vue';

inject();

export default {
  name: 'App',
  components: {
    Home,
    PageFooter
  }
}
</script>

<style>
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
}

.main {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.container {
  width: 80%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

@media (max-width: 768px) {
  .container {
    width: 100%;
    padding: 10px;
    padding-bottom: 65px;
  }
}
</style>